"use strict";

import "./js/inc/_jquery-global.js";
import "./js/inc/_jmap.js";
import "slick-carousel"
import "./scss/style.scss";

window.addEventListener("load", () => {
  const $j = jQuery.noConflict();

  /* header 固定・変化
  ----------------------------- */
  let scrollFlg = false;

  $j(window).on("scroll", function () {
    let currentPos = $j(window).scrollTop();

    if (currentPos > 300) {
      $j(".js-header").addClass('is-fixed');
      scrollFlg = true;
    } else if (!scrollFlg | currentPos == 0) {
      $j(".js-header").removeClass('is-fixed');
    }
  });

  /* スマホメニュー
  ----------------------------- */
  const navBtn = document.querySelector(".js-navBtn");
  const headerNav = document.querySelector(".js-headerNav");
  const headerBackground = document.querySelector(".js-headerBg");
  const headerNavItems = document.querySelectorAll(".js-headerNavItem");
  const bodyElement = document.querySelector("body");

  CustomEase.create("custom", ".47, .16, .24, 1")

  const openMenu = () => {
    const tl = gsap.timeline();
    tl.to(bodyElement, { onStart: () => bodyElement.classList.add("is-open") })
      .to(navBtn, { onStart: () => navBtn.classList.add("is-open") }, '<')
      .to(headerNav, { onStart: () => headerNav.classList.add("is-open") }, '<')
      .to(headerBackground, { onStart: () => headerBackground.classList.add("is-open") }, '<')
      .fromTo(headerNav, {x:"100%", autoAlpha:0}, {x:0, autoAlpha:1, duration: 0.6, ease: "custom"}, '<')
      .fromTo(headerBackground, {autoAlpha:0}, {autoAlpha:1, duration: 0.6, ease: "custom"}, '<')
      .fromTo(headerNavItems, {autoAlpha:0}, {autoAlpha:1, duration: 0.6, ease: "custom"}, '<')
    return tl;
  }
  
  const closeMenu = () => {
    const tl = gsap.timeline();
    tl.to(bodyElement, { onStart: () => bodyElement.classList.remove("is-open") })
      .to(navBtn, { onStart: () => navBtn.classList.remove("is-open") }, '<')
      .to(headerBackground, { onStart: () => headerBackground.classList.remove("is-open") }, '<')
      .to(headerNav, { onStart: () => headerNav.classList.remove("is-open") }, '<')
      .fromTo(headerNav, {x:0, autoAlpha:1}, {x:"100%", autoAlpha:0, duration: 0.6, ease: "custom"}, '<')
      .fromTo(headerNavItems, {autoAlpha:1}, {autoAlpha:0}, '<')
      .fromTo(headerBackground, {autoAlpha:1}, {autoAlpha:0}, '<');
    return tl;
  }

  navBtn.addEventListener("click", () => {
    if(navBtn.classList.contains('is-open')) {
      closeMenu();
    } else {
      openMenu();
    }
  });

  headerBackground.addEventListener("click", () => {
    if(headerBackground.classList.contains('is-open')) {
      closeMenu();
    }
  });

  function handleResize() {
    if (window.innerWidth >= 1200) {
      if(headerNav.classList.contains('is-open')) {
        closeMenu();
      }
    }
  }
  window.addEventListener('resize', handleResize);

  /* FVスライダー
  ----------------------------- */
  const fvSlider = document.querySelector('.js-fvSlider');
  if(fvSlider) {
    const slideLength = document.querySelectorAll('.fv__slide').length;
    if(slideLength > 1) {
      const fvSlider = new Swiper('.js-fvSlider', {
        loop: true,
        autoplay: true,
        slidesPerView: 1,
        allowTouchMove: false,
        speed: 2000,
        effect: 'fade',
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        fadeEffect: {
          crossFade: true,
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          waitForTransition: false,
        },
      });
    }
  }

  /* NESスライダー
  ----------------------------- */
  const newsSlider = document.querySelector('.js-newsSlider');
  
  if(newsSlider) {
    $j(function() {
      $j(".js-newsSlider").not('.slick-initialized')
      // プログレスバーの横幅（スライド枚数に合わせて調整）
      .on("init", function(event, slick) {
        const count = slick.slideCount;
        const dotsItems = document.querySelectorAll('.js-newsSlider .slider-dots li');
        dotsItems.forEach((dotsItem) => {
          dotsItem.style.setProperty('--count', count);
        });
      })
      .slick({
        pauseOnHover: false,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        dots: true,
        arrows: true,
        dotsClass: 'slider-dots',
          prevArrow:
            '<button class="slider-arrow slider-prev"><span class="arrow"></span></button>',
          nextArrow:
            '<button class="slider-arrow slider-next"><span class="arrow"></span></button>',
        variableWidth: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              centerMode: true,
              centerPadding: "15%",
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              centerMode: true,
              centerPadding: "15%",
            }
          }
        ]
      })
      // ブレイクポイントで関数を起動
      .on("breakpoint", function(event, slick, breakpoint) {
        const count = slick.slideCount;
        const dotsItems = document.querySelectorAll('.js-newsSlider .slider-dots li');
        dotsItems.forEach((dotsItem) => {
          dotsItem.style.setProperty('--count', count);
        });
      });
    });
  
    $j(function() {
      const slideLength = document.querySelectorAll(".slick-slide").length;
      if (slideLength <= 2) {
        $j(".js-newsSlider").addClass("is-slide-two");
        $j('.js-newsBox').addClass('is-slide-two');
      }
      $j(".js-newsSlider.is-slide-two.slick-initialized").slick("unslick");
    });
  
    $j(window).resize(function() {
      $j(".js-newsSlider.is-slide-two.slick-initialized").slick("unslick");
    });
  }

  /* TOPに戻る・アンカーリンク位置調整
  -------------------------------------------- */
  $j(function(){
    $j('a[href^="#"]').click(function() {
      let speed = 800;
      let type = 'swing';
      let href= $j(this).attr("href");
      let target = $j(href == "#" ? 'html' : href);
      let position = target.offset().top - 100;
      $j('body,html').animate({scrollTop:position}, speed, type);
      return false;
    });
  });

  /* 都道府県検索
  ----------------------------- */
  const jMapModalOpen = document.querySelector('.js-jMapModalOpen');
  
  if(jMapModalOpen) {
    // モーダル
    const buttonOpen = document.querySelector('.js-jMapModalOpen');
    const modal = document.querySelector('.js-jMapModal');
    const buttonClose = document.querySelector('.js-jMapModalClose');
    const bg = document.querySelector('.js-jMapModalBg');
    const bodyElement = document.querySelector('body');

    function modalOpen(event) {
      event.preventDefault();
      modal.style.display = 'block';
      bodyElement.classList.add("is-modalOpen");
    }
    
    function modalClose() {
      modal.style.display = 'none';
      bodyElement.classList.remove("is-modalOpen");
    }

    buttonOpen.addEventListener('click', modalOpen);
    buttonClose.addEventListener('click', modalClose);
    bg.addEventListener('click', modalClose);

    // 日本地図 Jmap jQuery plugin
    $j(document).ready(function() {
      $j('.js-jMap').jmap({
        showInfobox: true,
        prefectureClass: 'p-jMapModal__prefecture',
        prefectureLineColor: '#fff',
        prefectureLineWidth: 1,
        selectable: false,
        prefectureRadius: 0,
        showPrefectureName: true,
        areas: [
        {code : 1, name: "北海道", color: "#338140", hoverColor: "#b3b2ee" },
        {code : 2, name: "青森県", color: "#6986BE", hoverColor: "#98b9ff" },
        {code : 3, name: "岩手", color: "#6986BE", hoverColor: "#98b9ff" },
        {code : 4, name: "宮城", color: "#6986BE", hoverColor: "#98b9ff" },
        {code : 5, name: "秋田", color: "#6986BE", hoverColor: "#98b9ff" },
        {code : 6, name: "山形", color: "#6986BE", hoverColor: "#98b9ff" },
        {code : 7, name: "福島", color: "#6986BE", hoverColor: "#98b9ff" },
        {code : 8, name: "茨城", color: "#73C0D8", hoverColor: "#b7e5f4" },
        {code : 9, name: "栃木", color: "#73C0D8", hoverColor: "#b7e5f4" },
        {code : 10, name: "群馬", color: "#73C0D8", hoverColor: "#b7e5f4" },
        {code : 11, name: "埼玉", color: "#73C0D8", hoverColor: "#b7e5f4" },
        {code : 12, name: "千葉", color: "#73C0D8", hoverColor: "#b7e5f4" },
        {code : 13, name: "東京", color: "#73C0D8", hoverColor: "#b7e5f4" },
        {code : 14, name: "神奈川", color: "#73C0D8", hoverColor: "#b7e5f4" },
        {code : 15, name: "新潟", color: "#6AB17E", hoverColor: "#aceebb" },
        {code : 16, name: "富山", color: "#6AB17E", hoverColor: "#aceebb" },
        {code : 17, name: "石川", color: "#6AB17E", hoverColor: "#aceebb" },
        {code : 18, name: "福井", color: "#6AB17E", hoverColor: "#aceebb" },
        {code : 19, name: "山梨", color: "#6AB17E", hoverColor: "#aceebb" },
        {code : 20, name: "長野", color: "#6AB17E", hoverColor: "#aceebb" },
        {code : 21, name: "岐阜", color: "#6AB17E", hoverColor: "#aceebb" },
        {code : 22, name: "静岡", color: "#6AB17E", hoverColor: "#aceebb" },
        {code : 23, name: "愛知", color: "#6AB17E", hoverColor: "#aceebb" },
        {code : 24, name: "三重", color: "#F8A13C", hoverColor: "#fff19c" },
        {code : 25, name: "滋賀", color: "#F8A13C", hoverColor: "#fff19c" },
        {code : 26, name: "京都", color: "#F8A13C", hoverColor: "#fff19c" },
        {code : 27, name: "大阪", color: "#F8A13C", hoverColor: "#fff19c" },
        {code : 28, name: "兵庫", color: "#F8A13C", hoverColor: "#fff19c" },
        {code : 29, name: "奈良", color: "#F8A13C", hoverColor: "#fff19c" },
        {code : 30, name: "和歌山", color: "#F8A13C", hoverColor: "#fff19c" },
        {code : 31, name: "鳥取", color: "#E9672E", hoverColor: "#ffe0a3" },
        {code : 32, name: "島根", color: "#E9672E", hoverColor: "#ffe0a3" },
        {code : 33, name: "岡山", color: "#E9672E", hoverColor: "#ffe0a3" },
        {code : 34, name: "広島", color: "#E9672E", hoverColor: "#ffe0a3" },
        {code : 35, name: "山口", color: "#E9672E", hoverColor: "#ffe0a3" },
        {code : 36, name: "徳島", color: "#ED7187", hoverColor: "#ffbb9c" },
        {code : 37, name: "香川", color: "#ED7187", hoverColor: "#ffbb9c" },
        {code : 38, name: "愛媛", color: "#ED7187", hoverColor: "#ffbb9c" },
        {code : 39, name: "高知", color: "#ED7187", hoverColor: "#ffbb9c" },
        {code : 40, name: "福岡", color: "#E0B544", hoverColor: "#ffbdbd" },
        {code : 41, name: "佐賀", color: "#E0B544", hoverColor: "#ffbdbd" },
        {code : 42, name: "長崎", color: "#E0B544", hoverColor: "#ffbdbd" },
        {code : 43, name: "熊本", color: "#E0B544", hoverColor: "#ffbdbd" },
        {code : 44, name: "大分", color: "#E0B544", hoverColor: "#ffbdbd" },
        {code : 45, name: "宮崎", color: "#E0B544", hoverColor: "#ffbdbd" },
        {code : 46, name: "鹿児島", color: "#E0B544", hoverColor: "#ffbdbd" },
        {code : 47, name: "沖縄", color: "#E0B544", hoverColor: "#f5c9ff" }
        ],
        onLoad: function(e, data) {
          $j(this).html('<strong class="p-jMapModal__prefectureName">' + data.name + '</strong>');
        },
        onSelect: function(e, data) {
          const mapName = data.name + data.full;
          const selectedValue = $j('.js-jMapSelect').val(mapName);
          modalClose();
        },
      });
    });
  }

  /* フェードイン
  ----------------------------- */
  const fadeInItems = document.querySelectorAll('.js-g-fadeInAnime');
  if(fadeInItems) {
    gsap.utils.toArray(fadeInItems).forEach((fadeInItem) => {
      gsap.fromTo(fadeInItem, {autoAlpha:0}, {autoAlpha:1, duration:.8, delay:.25 ,scrollTrigger: {
        trigger: fadeInItem,
        start:'top 90%',
      }})
    });
  }

  /* 画像フェードイン
  ----------------------------- */
  const textEffectTriggers = document.querySelectorAll('.js-g-fadeInImageTrigger');
  if(textEffectTriggers){
    textEffectTriggers.forEach((trigger) => {
      const target = trigger.querySelectorAll('.js-g-fadeInImage');
      const textEffectTL = gsap.timeline({
        scrollTrigger:{
          trigger:target,
          start:'top 90%',
        }
      });
      textEffectTL
        .fromTo(target,
          {autoAlpha:0, scale:1.08, filter:"blur(2px)"},
          {autoAlpha:1, filter: "blur(0px)", scale:1, duration: .8,  delay:.25})
    });
  }

  /* 事業紹介 タブ切り替え
  ----------------------------- */
  const tabs = document.querySelectorAll('.js-tab');
  const tabContents = document.querySelectorAll('.js-tabContents');
  if(tabs) {
    tabs.forEach(function(tabListItem) {
      tabListItem.addEventListener('click', function() {
        const index = Array.prototype.indexOf.call(tabs, this);
  
        tabs.forEach(function(tab) {
          tab.classList.remove('is-tab-active');
          tab.setAttribute("aria-selected", false);
        });
        this.classList.add('is-tab-active');
        this.setAttribute("aria-selected", true);
  
        tabContents.forEach(function(tabContent) {
          tabContent.classList.remove('is-tab-active');
          tabContent.setAttribute("hidden", true);
        });
        tabContents[index].classList.add('is-tab-active');
        tabContents[index].removeAttribute("hidden");
      });
    });
  }

  /* 事業紹介 よくあるご質問
  ----------------------------- */
  const faq = document.querySelector('.js-faq');
  $j(".js-faqBox:first-of-type .js-faqAnswer").css("display", "block");
  $j(".js-faqBox:first-of-type .js-faqAnswer").addClass("is-active");
  $j(".js-faqBox:first-of-type .js-faqQuestion").addClass("is-active");

  if(faq){
    $j(".js-faqQuestion").on("click", function () {
      $j(".js-faqQuestion").not(this).next().slideUp(400);
      $j(".js-faqQuestion").not(this).next().removeClass("is-active");
      $j(".js-faqQuestion").not(this).removeClass("is-active");
      $j(this).next().slideToggle(400);
      $j(this).next().toggleClass("is-active");
      $j(this).toggleClass("is-active");
    });
  }
});


/* クエリがついていたら、製品一覧までスクロール
-------------------------------------------- */
window.addEventListener('DOMContentLoaded', (event) => {
  const productPage = document.getElementById('product');

  if(productPage){
    const url = new URL(window.location.href);
    
    if (url.search) {
      const productElement = document.getElementById('product-result');
      
      if (productElement) {
        setTimeout(() => {
          const headerHeight = document.querySelector('.js-header').offsetHeight;
          const elementPosition = productElement.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - headerHeight;
  
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }, 1000);
      }
    }
  }
});

/* TOPに戻るボタン 表示・非表示
-------------------------------------------- */
window.addEventListener('scroll', () => {
  const pageTop = document.querySelector('.js-pageTop');
  const currentY = window.pageYOffset;

  if(currentY >= 200) {
    setTimeout(() => {
      pageTop.style.opacity = 1;
      pageTop.style.visibility = 'visible';
    }, 1);
  } else {
    setTimeout(() => {
      pageTop.style.opacity = 0;
      pageTop.style.visibility = 'hidden';
    }, 1);
  }
});

/* 絞り込み検索 Ajax
-------------------------------------------- */
const topSearch = document.querySelector('.js-topSearch');
if(topSearch){
  jQuery(document).ready(function($) {
    // 初期状態で最新の投稿をロード
    function loadInitialPosts() {
      $.ajax({
        url: ajax_object.ajax_url,
        type: 'POST',
        data: {
          action: 'custom_search',
          initial_load: true,
          _ajax_nonce: ajax_object.nonce
        },
        success: function(response) {
          $('#search_result').html(response);
        }
      });
    }
  
    // ページ読み込み時に初期投稿をロード
    loadInitialPosts();
  
    $('#search_form').on('submit', function(e) {
      e.preventDefault();
      
      // フォームデータを取得
      var formData = $(this).serialize();
  
      // AJAXリクエスト
      $.ajax({
        url: ajax_object.ajax_url,
        type: 'POST',
        data: formData + '&action=custom_search&_ajax_nonce=' + ajax_object.nonce,
        success: function(response) {
          // 結果を表示
          $('#search_result').html(response);
        }
      });
    });
  
    // Ajaxの検索ボタンがクリックされたらNEWSを表示
    $('.js-searchSubmit').on('click', function() {
      $('.news__headImage').addClass('js-newsHeadImage');
      $('.news__contents').addClass('js-newsContents');
      
      setTimeout(function() {
        $('.search__result').addClass('is-result-show');
        $('.js-newsHeadImage, .js-newsContents').addClass('is-show');
      }, 500);
    });
    
    // フリーハンドバッグ検索ボタンがクリックされたときの処理
    // $('.js-freeHandBagSubmit').on('click', function(e) {
    //   e.preventDefault();
    //   // フォームデータを初期化
    //   var formData = 'action=custom_search&_ajax_nonce=' + ajax_object.nonce;
      
    //   // フリーハンドバックタグを追加
    //   formData += '&product_tag_word[]=フリーハンドバック';

    //   $.ajax({
    //     url: ajax_object.ajax_url,
    //     type: 'POST',
    //     data: formData + '&action=custom_search&_ajax_nonce=' + ajax_object.nonce,
    //     success: function(response) {
    //       $('#search_result').html(response);
    //     }
    //   });

    //   // リセット処理
    //   const searchForm = document.querySelector('#search_form');

    //   const selectElements = searchForm.querySelectorAll('select');
    //   selectElements.forEach(select => {
    //     const defaultValue = select.querySelector('option').value; // 最初のオプション（通常は「全て」または「なし」）をデフォルト値として設定
    //     select.value = defaultValue;
    //   });

    //   // チェックボックスの状態をリセット
    //   const checkboxElements = searchForm.querySelectorAll('input[type="checkbox"]');
    //   checkboxElements.forEach(checkbox => {
    //     checkbox.checked = false; // 全てのチェックボックスを未選択に設定
    //   });

    //   // テキスト入力のリセット
    //   const inputElements = searchForm.querySelectorAll('input[type="text"]');
    //   inputElements.forEach(input => {
    //     input.value = ''; // テキストフィールドを空にする
    //   });

    //   setTimeout(function() {
    //     $('.search__result').addClass('is-result-show');
    //     $('.js-newsHeadImage, .js-newsContents').addClass('is-show');
    //   }, 500);
    // });
  });
}

// 製品ページ：クエリパラメータがある場合に結果を表示
document.addEventListener('DOMContentLoaded', () => {
  // URLのパスとクエリ部分を取得
  const currentUrl = window.location.pathname;
  const searchParams = new URLSearchParams(window.location.search);

  // URLが "/product/" を含み、かつクエリパラメータが存在するかチェック
  if (currentUrl.includes('/product/') && searchParams.toString() !== '') {
    const productResult = document.querySelector('.js-productResult');
    if (productResult) {
      productResult.classList.add('is-result-show');
    }
  }
});

document.addEventListener('DOMContentLoaded', () => {
  const search = document.querySelector('.js-archiveSearch');
  if(search){
    // 製品ページ：全てのPDFを一括ダウンロード
    let index = 0;
    const downloadAllBtnTop = document.querySelector('.js-downloadAllBtnTop');
    const downloadAllBtnBottom = document.querySelector('.js-downloadAllBtnBottom');

    const downloadBtnsTop = document.querySelectorAll('.js-downloadBtnTop');
    const downloadBtnsBottom = document.querySelectorAll('.js-downloadBtnBottom');
  
    if (downloadAllBtnTop) {
      downloadAllBtnTop.addEventListener('click', () => {
        index = 0;
        downloadAllFnc(downloadBtnsTop);
      });
    }

    if (downloadAllBtnBottom) {
      downloadAllBtnBottom.addEventListener('click', () => {
        index = 0;
        downloadAllFnc(downloadBtnsBottom);
      });
    }

    const downloadAllFnc = (btns) => {
      while(index < btns.length){
        btns[index].click();
        index++;
      }
    }
  }
  
  // フォームリセット
  const searchForm = document.querySelector('#search_form');

  if (searchForm) {
    const resetBtn = document.querySelector('.js-searchReset');
    resetBtn.addEventListener('click', (event) => {
      event.preventDefault();

      // フォームリセット処理
      // 各セレクトボックスのデフォルト値を設定
      const selectElements = searchForm.querySelectorAll('select');
      selectElements.forEach(select => {
        const defaultValue = select.querySelector('option').value; // 最初のオプション（通常は「全て」または「なし」）をデフォルト値として設定
        select.value = defaultValue;
      });

      // チェックボックスの状態をリセット
      const checkboxElements = searchForm.querySelectorAll('input[type="checkbox"]');
      checkboxElements.forEach(checkbox => {
        checkbox.checked = false; // 全てのチェックボックスを未選択に設定
      });

      // テキスト入力のリセット
      const inputElements = searchForm.querySelectorAll('input[type="text"]');
      inputElements.forEach(input => {
        input.value = ''; // テキストフィールドを空にする
      });
    });

    // フォームの他のinput要素でEnterキーが押されたときの処理（リセットボタン以外の処理は何もしない）
    searchForm.querySelectorAll('input').forEach(input => {
      input.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
          event.preventDefault(); // Enterキーのデフォルト動作をキャンセル
        }
      });
    });
  }
});

document.addEventListener("DOMContentLoaded", function() {
  /* FVアニメーション
  ----------------------------- */
  const fv = document.querySelector('.js-fv');
  if(fv) {
    const sessionKey = "accesed";
    const sessionValue = true;

    if(!sessionStorage.getItem(sessionKey)){
      gsap.ticker.lagSmoothing(false);
      gsap.set('.js-g-header', {autoAlpha:0});
      gsap.set('.js-g-fvSlider', {autoAlpha:0});
      gsap.set('.js-g-fvPagination', {autoAlpha:0});
      gsap.set('.js-g-objects', {autoAlpha:0});
      gsap.set('.js-g-text01', {autoAlpha:0});
      gsap.set('.js-g-text02', {autoAlpha:0});
      gsap.set('.js-g-orange01', {autoAlpha:0, scale:1.08, filter:"blur(2px)"});
      gsap.set('.js-g-orange02', {autoAlpha:0, scale:1.08, filter:"blur(2px)"});
    
      gsap.timeline()
      .to('.js-fvContainer', {autoAlpha:1, duration: 1})
      .to('.js-g-fvSlider', {autoAlpha:1, duration: 1},"+=0.8")
      .to('.js-g-header', {autoAlpha:1, duration: 1})
      .to('.js-g-objects', {autoAlpha:1, duration: 1}, '<')
      .to('.js-g-fvPagination', {autoAlpha:1, duration: 1}, '<')
    
      .to('.js-g-orange01', {autoAlpha:1, filter: "blur(0px)", scale:1, duration: .6})
      .to('.js-g-text01', {autoAlpha:1, duration: 1}, '<')
      
      .to('.js-g-orange02', {autoAlpha:1, filter: "blur(0px)", scale:1, duration: .6})
      .to('.js-g-text02', {autoAlpha:1, duration: 1}, '<')
      sessionStorage.setItem(sessionKey, sessionValue);
    } else {
      gsap.timeline()
      .to('.js-fvContainer', {autoAlpha:1, duration: 1})
      .to('.js-g-fvSlider', {autoAlpha:1, duration: 0.8})
      .to('.js-g-header', {autoAlpha:1, duration: 0.8}, '<')
      .to('.js-g-objects', {autoAlpha:1, duration: 0.8}, '<')
      .to('.js-g-fvPagination', {autoAlpha:1, duration: 0.8}, '<')
      .to('.js-g-orange01', {autoAlpha:1, duration: 0.8, filter: "blur(0px)", scale:1}, '<')
      .to('.js-g-text01', {autoAlpha:1, duration: 0.8}, '<')
      .to('.js-g-orange02', {autoAlpha:1, duration: 0.8, filter: "blur(0px)", scale:1}, '<')
      .to('.js-g-text02', {autoAlpha:1, duration: 0.8}, '<')
    }
  }
});